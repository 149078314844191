<template>
  <div class="home">
    
    <div class="header">

      <div class="header-text">      
        <h3>Acil Yoğunluk Bilgilendirme Sistemi</h3>
        <p>Uygulamanın en temel amacı acil servislerdeki yoğunluğu belli bir oranda azaltmak, hastaların daha etkili ve hızlı bir şekilde hizmet almasını sağlamaktır. Bunun yanında sistemde yaşanan tıkanıklık, sağlık personelinin yaşadığı sorunlar, kalite standartları da iyileştirilecektir. </p>
      </div>

      <div class="header-image">
        <img src="../assets/acil.jpg" alt="">
      </div>


    </div>

  </div>

  <div class="home-body">


    <div class="home-body-sec">



      <div class="gallery">
    <div class="slider">
      <div> 
        <!-- <button @click="prevImage" id="prevBtn" class="btn-for-img">Önceki</button> -->
        <img  @click="prevImage" id="prevBtn" src="../assets/prev.png" alt="" class="image-btn">
      </div>
      <div><img :src="images[currentIndex]" :alt="`Foto ${currentIndex + 1}`" class="image-homepage" /></div>
      <div>
        <!-- <button @click="nextImage" id="nextBtn" class="btn-for-img">Sonraki</button> -->
        <img  @click="nextImage" id="nextBtn"  src="../assets/next.png" alt="" class="image-btn">

      </div>

      


    </div>

  </div>

    </div>

    <div class="home-body-details"> 
      <p>Ülkemizde özellikle nüfusun yoğunlaştığı büyük şehirlerde her ne kadar sağlık hizmeti bileşenlerinin kapasitesi sürekli iyileştirilmeye çalışılsa da talebin yüksek olması nedeniyle yoğunluklar yaşanabilmektedir. Yoğunluk yaşanan alanlardan birisi de <span style="color: red;">acil servisler</span>dir.</p>
    </div>

    <div>
      <ul>
        <h3>Acil servis hizmetleri,</h3>
        <li>kronik bir hastalığın akut atağı, </li>
        <li>ani gelişen hastalık, </li>
        <li>kaza, </li>
        <li>yaralanma vb.</li>
      </ul>
      
    </div>

    <div class="home-body-details">
      <p>beklenmeyen durumlarda oluşan sağlık sorunlarında, komplikasyon, morbidite, sakatlık ya da ölümden korunması amacıyla hastanın, acil serviste görevli sağlık personeli tarafından tıbbi araç ve gereç desteği ile değerlendirilmesi, tanısının konulması, hayati tehlikesini ortadan kaldıracak tıbbi müdahale ve tedavisinin yapılması, ileri tanı ve tedavisi için diğer hizmetlere devrine kadar yataklı sağlık tesislerinde sunulan acil sağlık hizmetlerini kapsamaktadır.</p>
    </div>

    <div class="home-body-details">
      <p>Beklenmeyen tıbbi acil durumlar nedeniyle acil servisler büyük bir talep ile karşı karşıya kalmaktadır. Acil servislerdeki yoğunluk sağlık sistemleri üzerinde büyük bir baskı oluşturabilir ve kaynakların etkili bir şekilde dağıtılmasını zorlaştırabilir. Bu nedenle, acil servislerin <span style="color: red;">verimliliği</span> ve <span style="color: red;">kapasitesi</span> <u>sürekli olarak izlenmeli ve iyileştirilmelidir.</u></p>
    </div>

    <div class="home-body-details">
      <p>Hızla büyüyen bu probleme çözüm geliştirmek üzere bir mobil uygulama geliştirmeyi ve bu uygulama ile acil servise ayaktan giriş yapmak isteyen hastalara, kendilerine yakın mesafede olan acil servisler içerisinden hem ulaşım mesafesi ve süresini hem de acil servisin yoğunluğunu göz önünde bulundurarak en uygun acil servis seçeneklerini sunmayı amaçlıyoruz. </p>
    </div>






  </div>

</template>

<script>
export default {
  
  data() {
    return {
      images: [
        require("../assets/img2.jpg"),
        require("../assets/img3.jpg"),
        require("../assets/img4.jpeg"),
        require("../assets/img5.jpeg"),
        require("../assets/img6.jpeg"),
        // Diğer fotoğrafları buraya ekleyin
      ],
      currentIndex: 0,
    };
  },
  methods: {
    prevImage() {
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    nextImage() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
  },
};
</script>


<style>
  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 800px;
    margin-left: 120px;
    gap: 20px;
    padding-top: 40px;
}


.header {
    display: flex;
    flex-direction: row;
}
.header h3 {
  font-size: 27px;
}

.header-text {
  width: 750px;
}
.header-image img {
  width: 540px;
  margin-left: 30px;
}

.home-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  height: 1400px;
  margin-bottom: 250px;
}

.image-homepage {
  width: 500px;
  border-radius: 10px;
  height: 300px;
  
}

.home-body-sec {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
}

.home-body-details {
  width: 700px;
  text-align: left;
}
.moon-star img {
  width: 70px;
  height: 50px;
}

.home-body ul li {
    padding-top: 25px;
    line-height: 2;
    word-spacing: 3px;
}


.slider button {
  border: 0;
  color: #ffff;
  cursor: pointer;
  border-radius: 10px;
  background-color: #00BFFF;
  transition:  0.2s;
  width: 70px;
  height: 30px;
}

.btn-for-img:hover {
  background-color: #ffff;
  color: #00BFFF;
}

.slider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-bottom: 75px;

}

.image-btn {

  width: 50px;
  height: auto;
  cursor: pointer;


}

@media only screen and (max-width: 850px) {

  .home {
    margin-left: 40px;
  }
  .header-image img {
  width: 270px;
  margin-left: 20px;
  margin-top: 50px;
}
.header-text {
  width: 300px;
  height: 300px;
  margin-top: 100px;
}
.header h3 {
  font-size: 17px;
}
.image-btn {
  width: 30px;
}
.image-homepage {
  width: 272px;
  height: 194px;
}
.home-body-details {
  width: 345px;

}
.header {
    display: flex;
    flex-direction: column;
}


}

</style>
